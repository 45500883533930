import React from 'react';
import IconEmail from '@dealroadshow/uikit/core/components/Icon/IconEmail';
import Input, { IInputProps } from '@dealroadshow/uikit/core/components/Input';
import { useMxValidation } from './useMxValidation';

interface IProps extends IInputProps {
  showIcon?: boolean,
  enableMxValidation?: boolean,
  warningMessage: string,
}

const EmailInput = ({
  showIcon = true,
  enableMxValidation = false,
  meta = {},
  input,
  warningMessage = 'Please verify your email address',
  ...props
}: IProps) => {
  const value = input?.value ?? props.value;
  const isMxValid = useMxValidation({
    enable: enableMxValidation,
    value,
  });

  return (
    <Input
      type="email"
      icon={ showIcon ? IconEmail : undefined }
      input={ input }
      { ...props }
      meta={ {
        ...meta,
        ...(enableMxValidation && !isMxValid && {
          warning: warningMessage,
          touched: true,
        }),
      } }
    />
  );
};

export default EmailInput;
