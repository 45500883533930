import { TFunction } from '@/Framework/i18n/types';
import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';
import { validateRequiredFields } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredFields';

const requiredFields = [
  { fieldName: 'email', fieldCaption: 'Email' },
  { fieldName: 'entryCode', fieldCaption: 'Entry Code' },
];
const email = { fieldName: 'email', fieldCaption: 'Email' };

export default (values, t: TFunction) => groupValidators(
    validateEmail({ ...email, value: values.email, message: t('invalidEmail') }),
    validateRequiredFields(requiredFields, values, (fieldName) => t('required', { field: t(fieldName) })),
  );
