import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import { useTranslation, Trans } from '@/Framework/i18n';
import EmailInput from '@/Framework/UI/Organisms/FinalForm/Fields/EmailInput';
import Input from '@dealroadshow/uikit/core/components/Input';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import FullScreenPreloader from '@dealroadshow/uikit/core/components/Loader/FullScreenPreloader';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import { INVESTOR_LOGIN_FORM_NAME } from '@/dealroadshow/ui/components/Landing/Institutional/InvestorLogin/constants';
import TermsOfUse from '@/Framework/UI/Atoms/TermsOfUse';
import { isEnglishLanguage } from '@/Framework/i18n/helpers/isEnglishLanguage';
import validate from './validator';
import LoginContext, { useLoginContext } from '@/users/application/Session/LoginContext';
import ErrorCodeHelper from '@finsight/error-codes';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import stripTags from '@/Framework/dom/stripTags';
import { namespaces } from '@/dealroadshow/ui/locales/namespaces';
import ja from './locales/ja.json';
import en from './locales/en.json';
import cardStyles from '@dealroadshow/uikit/core/styles/card.scss';
import customStyles from './customStyles.scss';
import styles from './investorLoginForm.scss';

interface IProps {
  isDirectLogin?: boolean,
  entryCode?: string,
  customSettings?: any,
  isCustomizationDisclaimerEnabled?: boolean,
}

interface IFormValues {
  entryCode: string,
  email: string,
  verificationToken?: string,
}

function InvestorLoginForm({
  isDirectLogin,
  entryCode,
  customSettings = {},
  isCustomizationDisclaimerEnabled,
}: IProps) {
  const { loginInvestorDrs } = useLoginContext();
  const { replace, query } = useRouter();
  const [showFullScreenPreloader, setShowFullScreenPreloader] = useState(false);
  const [hasCustomMessage, setHasCustomMessage] = useState(false);
  const [initialValues, setInitialValues] = useState<IFormValues>({
    entryCode,
    email: '',
  });

  const { t, i18nInstance: { language } } = useTranslation(namespaces.INVESTOR_LOGIN_FORM, { en, ja });

  const onSubmit = (values: IFormValues) => loginInvestorDrs(values).catch((e) => {
    setShowFullScreenPreloader(false);
    if (e?.getCode() === ErrorCodeHelper.getCodeByName('INVESTOR_VERIFICATION_EMAIL_SENT') ||
        e?.getCode() === ErrorCodeHelper.getCodeByName('USER_ACTIVATION_EMAIL_SENT')) {
      replace(`/login/investor/e/${ values.entryCode }/email_verification/${ values.email }`);
    }
  });

  const tryAutologin = async () => {
    const email = (query.email as string)?.replace(/ /g, '+');
    const entryCode = query.entry_code as string;
    const verificationToken = query.verification_token as string;

    if (email && entryCode) {
      const values: IFormValues = {
        email,
        entryCode,
        verificationToken,
      };
      setShowFullScreenPreloader(true);
      setInitialValues(values);
      onSubmit(values);
    }
  };

  useEffect(() => {
    setHasCustomMessage(!!stripTags(customSettings?.customMessage).length && customSettings?.customMessage !== null);
    tryAutologin();
  }, []);

  const getTitle = () => {
    if (isCustomizationDisclaimerEnabled) {
      return customSettings?.loginPrimaryText ?? '';
    }
    if (isDirectLogin && !isCustomizationDisclaimerEnabled) {
      return t('title');
    }
    return t('titleInstitutional');
  };

  const emailLabel = isDirectLogin ? t('email') : t('corporateEmailLabel');

  const renderFields = ({
    submitting,
    invalid,
  }, { Field }) => (
    <>
      <FullScreenPreloader isVisible={ showFullScreenPreloader } />
      <Field
        component={ EmailInput }
        inputClassName={ styles.loginFormInput }
        name="email"
        label={ (
          <div className={ cn({ [styles.customLoginForm]: isCustomizationDisclaimerEnabled }) }>
            { emailLabel }
          </div>
        ) }
        dataTest="investorLoginEmailInput"
        autoFocus
        showIcon={ false }
        enableMxValidation
        warningMessage={ t('verifyEmail') }
      />
      <div className={ styles.loginFormEntryCode }>
        <Field
          component={ Input }
          name="entryCode"
          inputClassName={ styles.loginFormInput }
          disabled={ isDirectLogin }
          dataTest="investorLoginEntryCodeInput"
          label={ (
            <div
              className={ cn(styles.loginFormLabel, {
                [styles.customLoginFormLabel]: isCustomizationDisclaimerEnabled,
              }) }
            >
              <Trans i18nKey={ `${ namespaces.INVESTOR_LOGIN_FORM }:fieldEntryCodeLabel` } components={ { span: <span /> } } />
              <span
                className={ cn(styles.loginFormLabelIcon, {
                  [styles.customLoginFormLabelIcon]: isCustomizationDisclaimerEnabled,
                }) }
              >
                <Tooltip
                  placement="top"
                  content={ t('entryCodeTooltip') }
                >
                  <IconQuestion />
                </Tooltip>
              </span>
            </div>
          ) }
          type="text"
        />
      </div>
      <Button
        type="submit"
        variant={ ButtonVariantType.action }
        className={ cn(styles.loginFormButton, {
          [customStyles.btn]: isCustomizationDisclaimerEnabled,
        }) }
        disabled={ invalid || submitting }
        block
        dataTest="investorLoginFormSubmitButton"
      >
        <>
          { isCustomizationDisclaimerEnabled ? t('customizedSubmitButton') : t('submitButton') }  { ' ' }
          <Spinner
            inline
            size="small"
            mode="light"
            isVisible={ submitting }
          />
        </>
      </Button>
    </>
  );

  return (
    <div className={ styles.investorLogin }>
      <div
        className={ cn(cardStyles.cardContainer, {
          [customStyles.customCardContainer]: isCustomizationDisclaimerEnabled,
        }) }
      >
        <div className={ cn(styles.investorLoginContainer, {
            [styles.customInvestorLoginContainer]: isCustomizationDisclaimerEnabled,
          }) }
        >
          <h1 className={ styles.title }>
            { getTitle() }
          </h1>
          <div className={ styles.caption }>
            { customSettings?.loginSecondaryText ?? t('loginSecondaryText') }
          </div>
          { hasCustomMessage && (
            <div
              className={ styles.customMessage }
              dangerouslySetInnerHTML={ { __html: customSettings?.customMessage } }
            />
          ) }
          <div className={ styles.loginForm }>
            <FinalForm<IFormValues>
              name={ INVESTOR_LOGIN_FORM_NAME }
              dataTest={ INVESTOR_LOGIN_FORM_NAME }
              onSubmit={ onSubmit }
              render={ renderFields }
              validate={ (values) => validate({ ...values }, t) }
              initialValues={ initialValues }
            />
          </div>
          <TermsOfUse
            className={ cn(styles.terms, {
              [customStyles.terms]: isCustomizationDisclaimerEnabled,
            }) }
          >
            <Trans
              i18nKey={ `${ namespaces.INVESTOR_LOGIN_FORM }:termsAndPrivacyButtons` }
              components={ {
                br: <br />,
                termsOfUse: <a href={ finsightWebUrl.getTermsOfUseUrl() } target="_blank" />,
                privacyPolicy: <a href={ finsightWebUrl.getPrivacyPolicyUrl() } target="_blank" />,
              } }
            />
          </TermsOfUse>
        </div>
        { isEnglishLanguage(language) && (
          <div className={ cn(styles.compatibilityLinkContainer, {
              [customStyles.customCompatibilityLinkContainer]: isCustomizationDisclaimerEnabled,
            }) }
          >
            <a
              href={ isDirectLogin ? `/compatibility?e=${ entryCode }&directLogin=true` : '/compatibility' }
            >
              Trouble viewing a roadshow?
            </a>
          </div>
        ) }
      </div>
    </div>
  );
}

export default ((props: IProps) => (
  <LoginContext>
    <InvestorLoginForm { ...props } />
  </LoginContext>
));
